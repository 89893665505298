import React from "react";

export const NotFinished = () => {
  return (
    <div className="notfinished">
      <h1>Not Finished</h1>
      <p>
        This part of the website isnt finished yet. I currently have only
        finished all of the POVs on the american revolution and the Abolitionist
        and Federalist POVs. I couldnt get to these pages before the deadline
        due to the writing for the other topics being longer than my english
        final.
        <br />
        <br />
        Fun Fact: This website took me around 2.5 hours to make. The info inside
        of the website took over 10 hours to gather and write
      </p>
    </div>
  );
};
